<template>
  <v-app :class="$vuetify.theme.dark ? undefined : 'grey lighten-3'">
    <v-spacer />
    <v-main>
      <router-view />
    </v-main>
    <v-spacer />
  </v-app>
</template>

<script>
export default {
    name: 'DashboardCoreView',
    data: () => ({}),
    computed: {}
}
</script>
